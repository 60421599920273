<template>
    <div id="app">
        not found
    </div>
</template>

<script>

export default {
    name: "NotFound404",
    data() {
        return {
        };
    },
}
</script>
